import { Result } from 'antd'
import Button from 'antd/lib/button'
import Link from 'next/link'

import styles from './error-page.styles.module.css'

const RESOURCES = {
  buttonText: 'Go home page',
  subTitleText: 'Page not found',
}

export default function NotFoundPage() {
  return (
    <div className={styles.container}>
      <Result
        extra={
          <Button>
            <Link href="/">{RESOURCES.buttonText}</Link>
          </Button>
        }
        status={404}
        subTitle={RESOURCES.subTitleText}
        title="404"
      />
    </div>
  )
}
